<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" :fullscreen="isScreenSmall ? true : false" persistent max-width="1200px">
      <v-card>
        <v-card-title>
          <div class="container text-right">
            <v-menu
                bottom
                right
              >
            </v-menu>
          </div>
          <h2>{{ $t('set_password') }}</h2>
          <p class='description mt-4'>{{ $t('setNewPassword') }}</p>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent class="modal-form">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :autocomplete="Math.random().toString(36)"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                    @change="checkPassword()"
                    v-model="password"
                    :label="$t('setNewPasswordInput')"
                    class="outlined__input login__input"
                    outlined
                    required
                  ></v-text-field>

                  <div v-if="passwordLengthError" class="v-messages error--text">{{ $t('shortPasswordError') }}</div>
                  <div v-if="passwordNumberError" class="v-messages error--text">{{ $t('passwordNumberError') }}</div>
                  <div v-if="passwordUppsercaseError" class="v-messages error--text">{{ $t('passwordUppsercaseError') }}</div>
                  <div v-if="sameCurrentPasswordError" class="v-messages error--text">{{ $t('sameCurrentPasswordError') }}</div>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :autocomplete="Math.random().toString(36)"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    @click:append="show2 = !show2"
                    @change="checkConfirmationPassword()"
                    v-model="passwordConfirmation"
                    :label="$t('setAgainNewPasswordInput')"
                    class="outlined__input login__input"
                    outlined
                    required
                  ></v-text-field>

                  <div v-if="checkSameConfirmationPassword && passwordConfirmation" class="v-messages error--text">{{ $t('samePasswordsError') }}</div>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-col sm="6" class="text-center">
            <button
              :disabled="!password || !passwordConfirmation || passwordLengthError || passwordNumberError || passwordUppsercaseError || checkSameConfirmationPassword"
              class="main-button main-button-fixed"
              @click="onSubmit()"
            >{{ $t('change_password_button') }}</button>
          </v-col>
          <v-col sm="6" class="text-right grey-link">
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { AppStorage } from 'cuf-expo-bridge'

export default {
  name: 'RegisterUserForm',
  data () {
    return {
      dialog: true,
      show1: false,
      show2: false,
      password: '',
      passwordConfirmation: '',
      passwordLengthError: false,
      passwordNumberError: false,
      passwordUppsercaseError: false,
      samePasswordsError: false,
      sameCurrentPasswordError: false
    }
  },
  computed: {
    ...mapGetters(['currentClient']),

    checkSameConfirmationPassword () {
      return this.password !== this.passwordConfirmation
    },

    isScreenSmall () {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
    }
  },
  methods: {
    checkPassword () {
      if (this.password.length > 9) {
        this.passwordLengthError = false
      } else {
        this.passwordLengthError = true
      }

      this.passwordNumberError = !/\d/.test(this.password)
      this.passwordUppsercaseError = !/[A-Z]/.test(this.password)
      this.samePasswordsError = this.password !== this.passwordConfirmation
      this.checkSamePassword()
    },
    checkConfirmationPassword () {
      this.samePasswordsError = this.password !== this.passwordConfirmation
    },
    checkSamePassword () {
      this.$http.put(`/client_accounts/${this.currentClient.id}/current_password/`, {
        data: { password: this.password }
      })
        .then(response => {
          this.sameCurrentPasswordError = response.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onSubmit () {
      if (this.password === this.passwordConfirmation) {
        this.$http.put(`/client_accounts/${this.currentClient.id}/change_password/`, {
          data: { password: this.password }
        })
          .then(() => {
            this.currentClient.reset_password = false
            this.currentClient.registered = true
            return this.$http.get(`/client_accounts/${this.currentClient.id}/refresh_token/`)
          })
          .then(responseToken => AppStorage.set('token', responseToken.data.token))
          .then(() => {
            this.$store.dispatch('getMessages')
            this.$router.push('/documents')
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .error-notice {
    color: red;
  }
</style>
